import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import is from './locales/is.json';

const resources = {
  en,
  is,
};

export type LanguageCode = keyof typeof resources;

export type Language = {
  code: LanguageCode;
  name: string;
  rtl?: boolean;
};

export default i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    resources,
    keySeparator: '.',
    lng: 'en',
    fallbackLng: 'en',
    saveMissing: true,
    returnObjects: true,
    missingKeyHandler: (locale, ns, key) => {
      const nodeEnv = process.env.NODE_ENV;
      if (nodeEnv && ['test', 'production'].includes(nodeEnv)) {
        return;
      }
      // eslint-disable-next-line no-console
      console.log(`Translations: Missing key '${key}' in locale ${locale}.`);
    },
  });
