import { ApolloProvider } from '@apollo/client';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { CssBaseline, inputClasses } from '@mui/material';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { ReactComponent as Logo } from './assets/logo.svg';
import client from './graphql';
import { getToken } from './graphql/token';
import NotLoggedIn from './routes/NotLoggedIn';
import UserProvider, { UserContext } from './context/user';
import { AlertProvider } from './context/alert';
import { DialogProvider } from 'muibox';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import hoppTheme from './theme';

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any;
  }

  interface Palette {
    status: {
      available: string;
      unavailable: string;
      inactive: string;
      removed: string;
    };
  }

  interface PaletteOptions {
    status: {
      available: string;
      unavailable: string;
      inactive: string;
      removed: string;
    };
  }
}

const Routes = React.lazy(() => import('./routes'));

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    common: {
      black: hoppTheme.colors.black,
      white: 'rgba(244, 244, 244, 1)',
    },
    background: {
      paper: hoppTheme.colors.black,
      default: 'rgba(52, 54, 57, 1)',
    },
    primary: {
      light: 'rgba(94, 255, 224, 1)',
      main: 'rgba(28, 229, 190, 1)',
      dark: 'rgba(26, 213, 189, 1)',
      contrastText: hoppTheme.colors.black,
    },
    secondary: {
      light: 'rgba(126, 174, 255, 1)',
      main: 'rgba(69, 127, 229, 1)',
      dark: 'rgba(62, 114, 208, 1)',
      contrastText: '#fff',
    },
    error: {
      light: 'rgba(255, 148, 176, 1)',
      main: 'rgba(255, 87, 131, 1)',
      dark: 'rgba(245, 166, 35, 1)',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(244, 244, 244, 1)',
      secondary: 'rgba(86, 106, 120, 1)',
      disabled: 'rgba(119, 119, 119, 1)',
    },
    status: {
      available: hoppTheme.colors.primary,
      unavailable: hoppTheme.colors.red,
      inactive: hoppTheme.colors.orange,
      removed: hoppTheme.colors.accent,
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          '&.Mui-disabled': {
            color: 'rgba(244, 244, 244, 1)',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'rgba(28, 229, 190, 1)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'rgba(119, 119, 119, 1)',
          },
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: 'rgb(43, 44, 46)',
          color: 'rgba(28, 229, 190, 1)',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.5)',
          width: '90vw',
          maxWidth: 300,
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        actions: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        },
        root: {
          display: 'flex',
        },
      },
    },
    MUIDataTableFilterList: {
      styleOverrides: {
        chip: {
          color: hoppTheme.colors.black,
          backgroundColor: 'rgba(28, 229, 190, 1)',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        deleteIcon: {
          color: '#1f2022',
          '&:hover': {
            color: '#1f202250',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #ffffff33',
          '@media (max-width:599.95px)': {
            borderBottom: '1px solid #ffffffaa !important',
            borderTop: '1px solid #ffffffaa !important',
          },
          '.MUIDataTableBodyCell-simpleCell-325:first-child': {
            paddingTop: '10px',
          },
          ':not(thead tr):last-child': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          [`& .${inputClasses.underline}:before`]: {
            borderBottomColor: '#263747',
          },
          [`& .MuiOutlinedInput-notchedOutline`]: {
            borderColor: hoppTheme.colors.muted,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiPaper: {
      styleOverrides: { root: { backgroundImage: 'unset' } },
    },
  },
});

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LogoWrap = styled.div<{ done?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: flex;
  flex-direction: column;
  text-align: center;

  svg {
    animation-name: ${spin};
    animation-delay: 0.3s;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    transform-origin: 51px 27px;
    animation-iteration-count: ${({ done }) => (done ? '1' : 'infinite')};
  }
`;

export const Loading: React.FC<{ done?: boolean }> = ({ done }) => (
  <LogoWrap done={done}>
    <Flipped flipId="logo">
      <div>
        <Logo />
      </div>
    </Flipped>
  </LogoWrap>
);

interface LoginHandlerProps {
  children: (
    isLoggedIn: boolean,
    login: () => void,
    loginError: string,
    clearError: () => void,
    canManage: boolean,
  ) => React.ReactNode;
}

let IntercomBootUserId: string | null = null;

function LoginHandler({ children }: LoginHandlerProps) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginError, setError] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [done, setDone] = useState(false);

  const {
    userLoading,
    scopes,
    canManage,
    name,
    email,
    fetchUser,
    oid: userId,
    phone,
  } = useContext(UserContext);

  useEffect(() => {
    if (isLoading && !userLoading) {
      setDone(true);
      setLoading(false);
    }
  }, [isLoading, userLoading]);

  useEffect(() => {
    const token = getToken();
    if (!token || token.length === 0) {
      return;
    }

    setIsLoggedIn(true);
  }, [scopes, canManage]);

  useEffect(() => {
    if ('intercomSettings' in window) {
      window.intercomSettings.hide_default_launcher = !userId || !!userLoading;
    }

    if (userLoading) {
      return;
    }
    // If we don't have userId but Intercom has one, then the user logged out.
    // As such we want to shut down Intercom to clear cookies and other stuff.
    // Also disable intercom for non-canManage users.
    if (!userId || !canManage) {
      if (IntercomBootUserId !== null) {
        IntercomBootUserId = null;
        window.Intercom('shutdown');
      }

      window.Intercom('hide');

      return;
    }

    // If we have userId but it doesn't match intercom boot user id, we
    // have a new user that we need to boot intercom with.
    if (userId && IntercomBootUserId !== userId) {
      IntercomBootUserId = userId;
      // Initialize Intercom chat widget
      window.Intercom('boot', {
        user_id: userId,
        is_operator: true,
        name,
        email,
        phone,
      });
    } else {
      // If we reach here, presumably name, email and phone was changed
      // while user was logged in. As such we want to update Intercom with
      // those information.
      window.Intercom('update', {
        name,
        email,
        phone,
      });
    }
  }, [userLoading, userId, name, email, phone, canManage]);

  const login = () => fetchUser();
  const clearError = () => setError('');

  return (
    <Flipper flipKey={isLoading}>
      {isLoading ? (
        <Loading done={done} />
      ) : (
        children(isLoggedIn, login, loginError, clearError, canManage)
      )}
    </Flipper>
  );
}

function App() {
  return (
    <ApolloProvider client={client}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <AlertProvider>
              <DialogProvider>
                <Suspense fallback={<Loading done={false} />}>
                  <UserProvider>
                    <LoginHandler>
                      {(
                        isLoggedIn,
                        login,
                        loginError,
                        clearError,
                        canManage,
                      ) =>
                        isLoggedIn ? (
                          <Routes hasElevatedPrivilages={canManage} />
                        ) : (
                          <NotLoggedIn
                            login={login}
                            loginError={loginError}
                            clearError={clearError}
                          />
                        )
                      }
                    </LoginHandler>
                  </UserProvider>
                </Suspense>
              </DialogProvider>
            </AlertProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  );
}

export default App;
