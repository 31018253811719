interface TokenState {
  token: string | null;
  refreshToken: string | null;
}

const TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refreshToken';
let isFirstLoad: boolean = true;

const updateToken = (newState: TokenState): void => {
  if (newState.token) {
    localStorage?.setItem(TOKEN_KEY, newState.token);
  }
  if (newState.refreshToken) {
    localStorage?.setItem(REFRESH_TOKEN_KEY, newState.refreshToken);
  }
};

const getToken = (): string | null => {
  // Is token in url?
  if (isFirstLoad) {
    isFirstLoad = false;
    const query = new URLSearchParams(window.location.search);
    if (query.has(TOKEN_KEY)) {
      // Save token.
      updateToken({
        token: query.get(TOKEN_KEY),
        refreshToken: null,
      });

      // Redirect and remove token from query string.
      query.delete(TOKEN_KEY);
      const newQuery = query.toString();
      const newSearch = newQuery ? `?${newQuery}` : '';
      window.location.replace(`${window.location.pathname}${newSearch}`);

      // No token for now.
      return null;
    }
  }

  return localStorage?.getItem(TOKEN_KEY) ?? null;
};

const getAuthorizationHeader = (): string => {
  const token = getToken();
  return token ? `Bearer ${token}` : '';
};

const getRefreshToken = (): string | null => {
  return localStorage?.getItem(REFRESH_TOKEN_KEY) ?? null;
};

const clearToken = (): void => {
  if (localStorage) {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem('lastSelectedFleet');
  }
  isFirstLoad = true;
};

const performLogout = () => {
  clearToken();
  window.location.pathname = '/';
};

export {
  getToken,
  getRefreshToken,
  getAuthorizationHeader,
  updateToken,
  clearToken,
  performLogout,
};
