export default {
  space: [0],
  fonts: {
    body: 'system-ui, sans-serif',
  },
  colors: {
    white: '#F4F4F4',
    black: '#1f2022',
    muted: '#263747',
    secondaryText: '#566a78',
    primary: '#1CE5BE',
    primaryDark: '#1AD5BD',
    accent: '#457FE5',
    red: '#FF5783',
    orange: '#FFB800',
    yellow: '#D3E262',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeights: {
    regular: 400,
    medium: 600,
    bold: 700,
  },
  radii: {
    primary: 8,
  },
  shadows: {
    card: '0px 4px 4px #1f2022, 0px 0px 8px rgba(31, 32, 34, 0.2)',
  },
  buttons: {
    primary: {
      fontWeight: 'bold',
      bg: 'primaryDark',
      borderRadius: 100,
      color: 'white',
      px: 40,
      py: 12,
      cursor: 'pointer',
      ':hover': {},
    },
    pillSmall: {
      bg: 'red',
      borderRadius: 100,
      color: 'white',
      px: 12,
      py: 4,
      lineHeight: 1,
      cursor: 'pointer',
    },
  },
};
