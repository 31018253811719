/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { getPin, verifyPin, AuthChannel } from '../../api/Login/login';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { LoadingButton } from '@mui/lab';
import { Alert, GlobalStyles, TextField } from '@mui/material';

interface NotLoggedInProps {
  login: () => void;
  loginError: string;
  clearError: () => void;
}
function NotLoggedIn({ login, loginError, clearError }: NotLoggedInProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [countryCode, setCountryCode] = useState('354');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pinNumber, setPinNumber] = useState('');
  const [pinToken, setPinToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const pinField = useRef<HTMLInputElement>();
  const [showCallRequest, setShowCallRequest] = useState(false);
  const [callRequested, setCallRequested] = useState(false);

  useEffect(() => {
    if (loginError) {
      setIsLoading(false);
      setCountryCode('354');
      setPhoneNumber('');
      setPinNumber('');
      setPinToken('');
      setErrorMessage('');
    }
  }, [loginError]);

  const getPinNumber = async () => {
    if (phoneNumber.length === 0 || Number.isNaN(parseInt(phoneNumber, 10))) {
      return;
    }

    setIsLoading(true);

    try {
      const token = await getPin(countryCode, phoneNumber);
      setPinToken(token);
      setErrorMessage('');

      clearError();

      requestAnimationFrame(() => {
        pinField.current?.focus();
      });
    } catch (error) {
      if (error.status === 429) {
        setErrorMessage(
          'Hold on, you tried too many times. Please wait for a few minutes before retrying.',
        );
      } else {
        setErrorMessage(
          'Oops, sorry! Looks like something went wrong, please try again.',
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const checkPin = async (pin: string) => {
    if (pin.length !== 4) {
      return;
    }

    setIsLoading(true);

    try {
      await verifyPin(pin, pinToken, countryCode, phoneNumber);
      login();
    } catch (error) {
      if (error.status === 400) {
        setErrorMessage('Oops! Looks like that PIN was incorrect.');
      } else {
        setErrorMessage(
          'Oops, sorry! Looks like something went wrong, please try again.',
        );
      }

      setIsLoading(false);
    }
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (pinToken) {
      if (pinNumber.length < 4) {
        setErrorMessage('Oops! Your pin is too short, it should be 4 numbers.');
        return;
      }
      if (pinNumber.length > 4) {
        setErrorMessage('Oops! Your pin is too long, it should be 4 numbers.');
        return;
      }
      checkPin(pinNumber);
    } else {
      getPinNumber();
    }
  };

  const requestPhoneCall = async () => {
    try {
      await getPin(countryCode, phoneNumber, AuthChannel.Call);
      setCallRequested(true);
    } catch (error) {
      if (error.status === 429) {
        setErrorMessage(
          'Hold on, you have tried too many times. Please wait for a few minutes before retrying.',
        );
      } else {
        setErrorMessage(
          'Oops, sorry! Looks like something went wrong, please try again.',
        );
      }
    }
  };

  useEffect(() => {
    if (!pinToken) {
      setShowCallRequest(false);
      return;
    }

    setTimeout(() => {
      setShowCallRequest(true);
    }, 10000);
  }, [pinToken]);

  const isSubmitDisabled =
    isLoading ||
    !phoneNumber ||
    !countryCode ||
    isLoading ||
    (pinToken && !pinNumber);

  return (
    <main
      css={css`
        display: flex;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
      `}
    >
      <GlobalStyles
        styles={{
          ':root, body': {
            backgroundColor: '#1f2022',
          },
        }}
      />
      <div
        css={css`
          height: 100%;
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (max-width: 768px) {
            width: 100%;
          }
        `}
      >
        <div
          css={css`
            max-width: 400px;
            display: grid;
            gap: 16px;
            grid-auto-flow: row;
            padding: 16px;
          `}
        >
          <a href="https://hopp.bike">
            <Logo width={95} />
          </a>
          <form
            css={css`
              display: flex;
              flex-direction: column;
            `}
            onSubmit={handleSubmit}
          >
            {!pinToken ? (
              <Fragment>
                <div
                  css={css`
                    display: flex;
                    margin-bottom: 24px;
                  `}
                >
                  <div
                    css={css`
                      flex-shrink: 1;
                    `}
                  >
                    <TextField
                      id="countryCode"
                      type="tel"
                      required
                      value={countryCode}
                      onChange={event => setCountryCode(event.target.value)}
                      autoComplete="false"
                      label="Country"
                      fullWidth={false}
                      css={css`
                        flex-basis: 70px;
                        color: red;
                        width: 100%;
                      `}
                    />
                  </div>
                  <div
                    css={css`
                      flex-basis: 400px;
                      flex-grow: 1;
                      margin-left: 16px;
                    `}
                  >
                    <TextField
                      id="phoneNumber"
                      type="tel"
                      autoFocus
                      required
                      placeholder="766-5665"
                      value={phoneNumber}
                      autoComplete="true"
                      fullWidth
                      label="Phone Number"
                      onChange={event => setPhoneNumber(event.target.value)}
                    />
                  </div>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <Alert severity="info">
                  We will send you a pin code to verify your phone number.
                </Alert>
                <TextField
                  id="pipinCodenCode"
                  inputRef={pinField}
                  type="number"
                  required
                  disabled={isLoading}
                  label="Pin Code"
                  autoFocus
                  onChange={event => setPinNumber(event.target.value)}
                  css={{
                    marginBottom: '24px',
                    marginTop: '24px',
                    textAlign: 'center',
                  }}
                />
                {callRequested ? (
                  <label
                    css={css`
                      font-size: 16px;
                      font-weight: bold;
                      margin-bottom: 16px;
                    `}
                    htmlFor="pinCode"
                  >
                    {
                      'You will receive a phone call shortly where we will tell you the pin code!'
                    }
                  </label>
                ) : null}
              </Fragment>
            )}

            <div
              css={css`
                display: grid;
                grid-auto-flow: row;
                gap: 16px;
              `}
            >
              {errorMessage.length ? (
                <Alert severity="error">{errorMessage}</Alert>
              ) : null}
              {loginError.length ? (
                <Alert severity="error">{loginError}</Alert>
              ) : null}
              <LoadingButton
                type="submit"
                onClick={handleSubmit}
                disabled={!!isSubmitDisabled}
                loading={isLoading}
                variant="contained"
                fullWidth
              >
                {pinToken ? 'Login' : 'Get Pin'}
              </LoadingButton>

              {pinToken && !callRequested && showCallRequest && (
                <LoadingButton
                  onClick={requestPhoneCall}
                  disabled={isLoading}
                  loading={isLoading}
                  fullWidth
                >
                  Request Phone Call
                </LoadingButton>
              )}
            </div>
          </form>
        </div>
      </div>
      <div
        css={css`
          height: 100%;
          width: 50%;
          position: relative;

          @media (max-width: 768px) {
            display: none;
          }
        `}
      >
        <img
          alt=""
          sizes="100vw"
          srcSet="//images.ctfassets.net/km7oukpk8w6t/4fOeGdcD13Y1yZTkyo2jCJ/2253e2d201d2f49638192a80e93b2cb8/Operations_export.jpg?w=376&amp;q=75 376w, //images.ctfassets.net/km7oukpk8w6t/4fOeGdcD13Y1yZTkyo2jCJ/2253e2d201d2f49638192a80e93b2cb8/Operations_export.jpg?w=768&amp;q=75 768w, //images.ctfassets.net/km7oukpk8w6t/4fOeGdcD13Y1yZTkyo2jCJ/2253e2d201d2f49638192a80e93b2cb8/Operations_export.jpg?w=1024&amp;q=75 1024w, //images.ctfassets.net/km7oukpk8w6t/4fOeGdcD13Y1yZTkyo2jCJ/2253e2d201d2f49638192a80e93b2cb8/Operations_export.jpg?w=1440&amp;q=75 1440w, //images.ctfassets.net/km7oukpk8w6t/4fOeGdcD13Y1yZTkyo2jCJ/2253e2d201d2f49638192a80e93b2cb8/Operations_export.jpg?w=1920&amp;q=75 1920w"
          src="//images.ctfassets.net/km7oukpk8w6t/4fOeGdcD13Y1yZTkyo2jCJ/2253e2d201d2f49638192a80e93b2cb8/Operations_export.jpg?w=1920&amp;q=75"
          decoding="async"
          data-nimg="fill"
          css={css`
            height: 100%;
            width: 100%;
            object-fit: cover;
            mix-blend-mode: color-dodge;
          `}
        />
      </div>
    </main>
  );
}

export default NotLoggedIn;
